@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap);
:root {
  /* --font-base: "Nunito", sans-serif;
  --font-alt: "Nunito Sans", sans-serif; */
  --font-base: "Graphik,Helvetica Neue,Helvetica",sans-serif;
  --font-alt: "Graphik,Helvetica Neue,Helvetica",sans-serif;
  --color-font: #2b394e;
  --accent-color: #646eff;

  --color-one: #f4f4f7;
  --color-two: #ededf1;
  --color-three: #b8b8bd;
}

/* Graphik,Helvetica Neue,Helvetica,sans-serif */
.okiro__footer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  padding: 0rem 4rem;
  -webkit-user-select: none;
          user-select: none;
}

.okiro__footer-content {
  display: flex;
  flex-direction: row;
  padding: 0 2rem;

  margin-top: 3rem;
}

.okiro__footer-brand {
  display: flex;
  flex-direction: column;
  flex: 2.5 1;
}

.okiro__footer-brand img {
  width: 100px;
  height: 35px;

  margin-bottom: 1rem;
}

.okiro__footer-brand p {
  width: 80%;
  font-family: var(--font-alt);
  color: var(--color-font);
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;

  margin-bottom: 2rem;
}

.okiro__footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 2 1;
}

.okiro__footer-copyright {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.7rem;
}

.okiro__footer-copyright div {
  font-family: var(--font-base);
  color: var(--color-font);
  font-size: 12px;
  font-weight: 600;
}

.okiro__footer-links_div {
  padding: 1rem 2rem 1rem 0rem;
  flex: 1 1;
}

.okiro__footer-links_div h3 {
  font-weight: 900;
  font-family: var(--font-base);
  font-size: 10px;
  line-height: 1;
  letter-spacing: 4px;
  color: var(--color-font);
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.okiro__footer-links_div-linkDiv {
  display: flex;
  flex-direction: column;
}

.okiro__footer-links_div-linkDiv p {
  color: var(--color-font);
  font-size: 16px;
  font-weight: 900;
  font-family: var(--font-base);
  text-transform: capitalize;
  line-height: 1.5;

  margin: 0.5rem 0;
  cursor: pointer;
  white-space: nowrap;
}

.okiro__footer-links_div-linkDiv p:hover {
  color: #132033;
}

.okiro__footer-brand_media {
  display: flex;
  flex-wrap: wrap;
}

.okiro__footer-brand_media svg {
  margin: 0rem 1.5rem 1rem 0rem;
  font-size: 30px;
  cursor: pointer;
  fill: var(--color-font);
}

@media screen and (max-width: 900px) {
  .okiro__footer-content {
    flex-direction: column;
  }

  .okiro__footer-links {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 750px) {
  .okiro__footer-content {
    padding: 0;
  }

  .okiro__footer-copyright {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .okiro__footer {
    padding: 0 1rem;
  }
}

.okiro__custom-button {
  display: flex;
}

.okiro__custom-button button {
  background: var(--accent-color);
  color: #fff;
  border: 2px solid var(--accent-color);
  padding: 0.9rem 1.5rem;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  font-weight: 800;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  opacity: 0.8;
  transition: opacity 0.3s ease-in;
}

.okiro__custom-button button:hover {
  opacity: 1;
}

.okiro__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  background-color:#fff;

  padding: 1.2rem 4rem;
  position: fixed;
  width: 100%;
  -webkit-user-select: none;
          user-select: none;

  border-bottom: 2px solid #e8e9ea;

  top: 0%;
  z-index: 99999;
}

.okiro__header__logo {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.okiro__header__logo img {
  width: 200px;
  height: 60px;

  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.okiro__header__links,
.okiro__header__sign {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}

.okiro__header__links_search {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  flex: 1 1;
}



.style-font{
  font-style: italic;
}

.okiro__header__links ul,
.okiro__header__sign ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.okiro__header__links li,
.okiro__header__sign li,
.okiro__header__hamburger--linksDiv li {
  display: flex;
  list-style: none;
  margin: 0rem 0.7rem;

  font-family: var(--font-base);
  font-size: 19px;
  line-height: 1.5;
  color: var(--color-font);
  font-weight: 900;
  cursor: pointer;
  position: relative;
}

.okiro__header__links {
  position: relative;
}

.okiro__header__dotLinks {
  display: flex;
  justify-content: flex-start;
  min-width: 170px;
  position: absolute;

  margin: 2rem 0rem 0rem -3rem;

  background: var(--color-two);
  padding: 1rem 0.7rem;
  border-radius: 10px;

  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.okiro__header__dotLinks ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.okiro__header__dotLinks li {
  text-align: left;
  margin: 0.3rem 0.7rem;
}

.special-link:hover:before,
.special-link:hover:before {
  position: absolute;
  width: 7px;
  height: 7px;
  content: "";
  border-radius: 100%;
  background: var(--color-three);
  top: 1px;
  right: -7px;

  transition: background-color 0.3s ease;
}

.special-link:active:before,
.special-link:focus:before {
  background: var(--accent-color);
}

.isActive::before {
  background: var(--accent-color);
  position: absolute;
  width: 7px;
  height: 7px;
  content: "";
  border-radius: 100%;
  top: 1px;
  right: -7px;
}

.okiro__header__hamburger {
  display: none;
  flex: 1 1;
  justify-content: flex-end;
  align-items: center;

  position: relative;
}

.okiro__header__hamburger--linksDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 1.5rem;

  background-color: var(--color-two);
  top: 170%;
  right: -1%;
  border-radius: 10px;
  min-width: 170px;
  z-index: 5;

  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.okiro__header__hamburger--linksDiv ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0rem 1rem;
}

.okiro__header__hamburger--linksDiv li {
  margin: 0.3rem;
  font-size: 16px;
  -webkit-user-select: none;
          user-select: none;
}

.okiro__header__hamburger--linkDiv-search {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--accent-color);
  padding: 1rem;
  margin-top: 0.7rem;

  font-family: var(--font-base);
  letter-spacing: 0.5px;
  color: #fff;
  font-weight: 700;
  text-transform: capitalize;
  cursor: pointer;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.okiro__header__hamburger--linkDiv-search svg {
  margin-left: 10px;
}

.okiro__subscribe {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

}

.okiro__subscribe-text {
  display: flex;
  margin-right: 1rem;
}

.okiro__subscribe-text h2 {
  font-size: 46px;
  line-height: 1.1;
  font-weight: 800;
  font-family: var(--font-base);
  color: var(--color-font);
}

.okiro__subscribe-input {
  display: flex;
  /* background: var(--color-two); */
  padding: 0.75rem;

  border-radius: 50px;
}

.okiro__subscribe-input input {
  font-size: 15px;
  font-family: var(--font-alt);
  outline: none;
  font-weight: 500;
  border: none;
  width: 210px;
  background: transparent;
  padding: 0 0.5rem;
  transition: width 0.3s ease 0.2s;
}

.okiro__subscribe-input input:focus,
.okiro__subscribe-input input:active {
  width: 350px;
}

.okiro__header__sign {
  justify-content:flex-start;
}


@media screen and (max-width: 1485px) {
  .okiro__header {
    padding: 0.7rem 4rem;
  }

  .okiro__header__sign {
    justify-content:center;
  }
}

@media screen and (max-width: 1010px) {
  .okiro__header__sign,
  .okiro__header__links {
    display: none;
  }

  .okiro__header__logo {
    justify-content: flex-start;
  }

  .okiro__header__hamburger {
    display: flex;
  }


}

@media screen and (max-width: 600px) {
  .okiro__header {
    padding: 1rem;
  }

  .okiro__header__logo img {
    width: 180px;
    height: 50px;
  }

  .okiro__header__links_search{
    display: none;
  }
}

.okiro__post-tag {
  padding: 0.7rem;
  background: rgba(0, 0, 0, 0.06);
  width: -webkit-max-content;
  width: max-content;

  border-radius: 15px;
  font-family: var(--font-base);
  font-size: 9px;
  line-height: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-indent: 3px;
  font-weight: 800;
  cursor: pointer;
}

.okiro__article-card {
  /* min-width: 290px; */
  display: flex;
  flex-direction: column;
  max-width: 350px;

  background: #ffeab9;
  border-radius: 25px;

  margin: 1rem 1.5rem;

  transition: -webkit-transform 0.2s ease;

  transition: transform 0.2s ease;

  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.okiro__article-card:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.okiro__article-card_img {
  display: flex;
  width: 100%;
}

.okiro__article-card_img a {
  width: 100%;
}

.okiro__article-card_img img {
  width: 100%;
  height: 330px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  cursor: pointer;
}

.okiro__article-card_content {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.okiro__article-card_content-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.okiro__article-card_content-name h3 {
  font-size: 26px;
  line-height: 1.2;
  width: 100%;
  color: var(--color-font);
  font-weight: 900;
  font-family: var(--font-base);
  cursor: pointer;
}

.okiro__article-card_content-name p {
  margin-top: 0.5rem;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 800;
  color: var(--color-font);
  width: 100%;
  font-family: var(--font-alt);
  cursor: pointer;
}

.okiro__article-card_content-tag {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.okiro__article-card_content-tag_private {
  width: 35px;
  height: 35px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.okiro__article-card_content-tag_private svg {
  fill: var(--color-font);
}

.okiro__homepage {
  display: flex;
  flex-direction: column;

  padding: 2rem;
}

.okiro__homepage__aritclesDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 2rem 0;
}

.okiro__homepage__show-articles {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  margin-bottom: 1rem;
}

@media screen and (max-width: 1000px) {
  .okiro__homepage__show-articles {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 450px) {
  .okiro__homepage {
    padding: 2rem 0.5rem;
  }
}

.okiro__infocard {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  max-height: 375px;
  border-radius: 2rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 4rem 4rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 630px) {
  .okiro__infocard {
    padding: 2rem;
    justify-content: center;
    align-items: center;

    border-radius: 1rem;
  }
}

@media screen and (max-width: 350px) {
  .okiro__infocard {
    padding: 1rem;
  }
}

.okiro__membership {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 2rem 4rem;
}

.okiro__membership-welcome_text {
  display: flex;
  flex-direction: column;

  padding: 1.5rem 3rem;
  background: #fff;
  border-radius: 2rem;

  max-width: 450px;
}

.okiro__membership-welcome_text h3 {
  font-size: 45px;
  line-height: 1.2;
  font-family: var(--font-base);
  text-transform: capitalize;
  font-weight: 900;
  color: var(--color-font);
  word-wrap: break-word;
  word-break: break-all;

  margin: 0.5rem 0rem 1.5rem;
}

.okiro__membership-welcome_text svg {
  width: 90px;
  fill: rgba(0, 0, 0, 0.06);
}

.okiro__membership-welcome_text p {
  margin: 0.8rem 0rem 1rem;
  font-family: var(--font-alt);
  color: var(--color-font);
  font-weight: 800;
  font-size: 18px;
  line-height: 1.5;
  text-align: left;
}

.okiro__membership-plans {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 2rem 0;
}

.okiro__membership-plans h3 {
  font-family: var(--font-base);
  font-size: 22px;
  font-weight: 900;
  text-align: center;
  line-height: 1.5;
  color: var(--color-font);
}

.okiro__membership-plans_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  margin-top: 1rem;
}

.okiro__membership-plans_div-card {
  background: var(--color-two);
  border-radius: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 1;
  width: 100%;

  margin: 1rem 1.5rem;

  min-width: 320px;
}

.okiro__membership-plans_div-card_type {
  background: var(--accent-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;

  padding: 0.8rem;
  color: #fff;
  font-weight: 800;
  font-family: var(--font-alt);
  font-size: 10px;
  line-height: 1;

  text-indent: 4px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.okiro__membership-plans_div-card_amount {
  display: flex;
  flex-direction: row;
  padding: 2rem;
}

.okiro__membership-plans_div-card_amount svg {
  fill: var(--color-font);
}

.okiro__membership-plans_div-card_amount h1 {
  font-size: 5rem;
  font-weight: 900;
  color: var(--color-font);
  font-family: var(--font-base);

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.okiro__membership-plans_div-card_amount span {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
}

.okiro__membership-plans_div-card_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 0rem 2rem 2rem;
}

.okiro__membership-plans_div-card_content p {
  font-size: 16px;
  line-height: 1.3;
  font-family: var(--font-alt);
  color: var(--color-font);
  margin: 0.5rem;
  text-align: center;
}

.okiro__membership-plans_div-card_button {
  display: flex;
  padding: 2rem;

  margin-top: 2rem;
}

.okiro__membership-plans_div-card_button .okiro__custom-button button {
  letter-spacing: 0.5px;
  padding: 1.2rem 1.5rem;
  font-weight: 900;
  font-family: var(--font-base);
  opacity: 1;
}

@media screen and (max-width: 630px) {
  .okiro__membership-welcome_text {
    width: 100%;
    max-width: 100%;
    border-radius: 1rem;
  }

  .okiro__membership-welcome_text h3 {
    font-size: 30px;
  }

  .okiro__membership-welcome_text p {
    font-size: 15px;
  }

  .okiro__membership-plans_div-card {
    margin: 1rem 0.5rem;
  }
}

@media screen and (max-width: 450px) {
  .okiro__membership {
    padding: 2rem 1.5rem;
  }

  .okiro__membership-welcome_text {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 350px) {
  .okiro__membership {
    padding: 2rem 0.5rem;
  }

  .okiro__membership-plans_div-card {
    min-width: 100%;
  }
}

.toast-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  padding: 20px;
}

.toast-container .toast .toast-header.text-bg-success {
  background-color: #5cb85c;
  color: #fff;
}

.toast-container .toast .toast-header.text-bg-error {
  background-color: #d9534f;
  color: #fff;
}

.toast-container .toast .toast-header.text-bg-warning {
  background-color: #f0ad4e;
  color: #fff;
}

.toast-container .toast .toast-header.text-bg-info {
  background-color: #5bc0de;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .toast-container {
    right: 50%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
}
.okiro__connectwithus {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.okiro__connectwithus_image-container {
  flex: 1.5 1;
  display: flex;
}

.okiro__connectwithus_image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.okiro__connectwithus_form-container {
  flex: 2 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 2rem;
}

.okiro__connectwithus_form-container__logo {
  display: flex;

  margin-bottom: 2rem;
}

.okiro__connectwithus_form-container__logo img {
  width: 240px;
  height: 70px;
  cursor: pointer;
}

.okiro__connectwithus_form-container__heading {
  display: flex;
  text-align: center;

  margin-bottom: 2rem;
}

.okiro__connectwithus_form-container__heading h1 {
  font-size: 42px;
  line-height: 1.2;
  font-weight: 900;
  color: var(--color-font);
  font-family: var(--font-base);
}

.okiro__connectwithus_form-container__fields {
  display: flex;
  width: 80%;
  flex-direction: column;

  margin-bottom: 2rem;
}

.okiro__connectwithus_form-container__fields-input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  margin: 1rem 0rem;
}

.okiro__connectwithus_form-container__fields-input p,
.okiro__connectwithus__form-container__fields-textarea p {
  font-size: 13px;
  text-align: center;
  font-family: var(--font-alt);
  font-weight: 500;
  color: var(--color-font);

  margin-bottom: 0.5rem;
}

.okiro__connectwithus_form-container__fields-input input {
  font-size: 22px;
  text-align: center;
  border-radius: 3rem;
  padding: 1.5rem 1rem;
  background-color: #f4f4f7;
  width: 100%;
  line-height: 1.3;
  font-family: var(--font-alt);
  outline: none;
  border: none;
  transition: background-color 0.15s ease;
}

.okiro__connectwithus_form-container__fields-input input:focus,
.okiro__connectwithus_form-container__fields-input input:active,
.okiro__connectwithus_form-container__fields-input input:hover {
  background-color: #ededf1;
}

.okiro__connectwithus__form-container__fields-textarea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: 1rem 0rem;
}

.okiro__connectwithus__form-container__fields-textarea textarea {
  width: 100%;
  font-size: 16px;
  transition: border-color 0.15s ease;
  border: 4px solid #f4f4f7;
  border-radius: 2rem;

  outline: none;
  padding: 1rem;
  line-height: 1.3;
  font-family: var(--font-alt);
}

.okiro__connectwithus__form-container__fields-textarea textarea:focus,
.okiro__connectwithus__form-container__fields-textarea textarea:active {
  border-color: var(--accent-color);
}

.okiro__connectwithus_form-container__fields-sign {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 1rem 0;
}

.okiro__connectwithus_form-container__fields-sign p {
  font-size: 13px;
  text-align: center;
  font-family: var(--font-base);
  font-weight: 400;
  color: var(--color-font);
  line-height: 1.5;
}

.okiro__connectwithus_form-container__fields-sign span {
  font-weight: 900;
  cursor: pointer;
}

@media screen and (max-width: 950px) {
  .okiro__connectwithus {
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    align-items: center;
  }

  .okiro__connectwithus_image-container {
    height: 100px;
  }

  .okiro__connectwithus_form-container {
    width: 100%;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 570px) {
  .okiro__connectwithus_form-container {
    padding: 2rem 0.5rem;
  }

  .okiro__connectwithus_form-container__heading h1 {
    font-size: 22px;
  }

  .okiro__connectwithus_form-container__fields input {
    padding: 1rem;
  }
}

@media screen and (max-width: 380px) {
  .okiro__connectwithus_form-container__fields {
    width: 95%;
  }
}

.okiro__memberonly {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.okiro__memberonly::before {
  position: absolute;
  right: 50%;
  left: 50%;
  top: -75%;
  width: calc(100vw - 40px);
  height: 80%;
  margin: 0 calc(-50vw + 20px);
  content: "";
  pointer-events: none;
  background-color: #fff;
  -webkit-mask-image: linear-gradient(
    rgb(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.013) 8.1%,
    rgba(0, 0, 0, 0.049) 15.5%,
    rgba(0, 0, 0, 0.104) 22.5%,
    rgba(0, 0, 0, 0.175) 29%,
    rgba(0, 0, 0, 0.259) 35.3%,
    rgba(0, 0, 0, 0.352) 41.2%,
    rgba(0, 0, 0, 0.45) 47.1%,
    rgba(0, 0, 0, 0.55) 52.9%,
    rgba(0, 0, 0, 0.648) 58.8%,
    rgba(0, 0, 0, 0.741) 64.7%,
    rgba(0, 0, 0, 0.825) 71%,
    rgba(0, 0, 0, 0.896) 77.5%,
    rgba(0, 0, 0, 0.951) 84.5%,
    rgba(0, 0, 0, 0.987) 91.9%,
    #000 100%
  );
  mask-image: linear-gradient(
    rgb(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.013) 8.1%,
    rgba(0, 0, 0, 0.049) 15.5%,
    rgba(0, 0, 0, 0.104) 22.5%,
    rgba(0, 0, 0, 0.175) 29%,
    rgba(0, 0, 0, 0.259) 35.3%,
    rgba(0, 0, 0, 0.352) 41.2%,
    rgba(0, 0, 0, 0.45) 47.1%,
    rgba(0, 0, 0, 0.55) 52.9%,
    rgba(0, 0, 0, 0.648) 58.8%,
    rgba(0, 0, 0, 0.741) 64.7%,
    rgba(0, 0, 0, 0.825) 71%,
    rgba(0, 0, 0, 0.896) 77.5%,
    rgba(0, 0, 0, 0.951) 84.5%,
    rgba(0, 0, 0, 0.987) 91.9%,
    #000 100%
  );
}

.okiro__memberonly_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: #d2f8f0;
  padding: 2rem 4rem;
  text-align: center;

  max-width: 700px;
  border-radius: 2rem;
  z-index: 1;
  margin-top: 2rem;
}

.okiro__memberonly_text {
  display: flex;
  flex-direction: column;
}

.okiro__memberonly_text h1 {
  padding: 0rem 1rem;
  font-size: 41px;
  line-height: 1.4;
  font-weight: 800;
  color: var(--color-font);
}

.okiro__memberonly_text p {
  line-height: 1.65 !important;
  color: var(--color-font) !important;
  font-size: 20px !important;
  font-family: var(--font-base) !important;
  font-weight: 400 !important;

  margin: 1rem 0rem 2rem !important;
}

.okiro__memberonly .okiro__custom-button button {
  letter-spacing: 0;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 900;
  font-family: var(--font-alt);
}

.okiro__memberonly_box_para {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 1.25rem;
}

.okiro__memberonly_box_para p {
  font-size: 13px !important;
  text-align: center !important;
  color: var(--color-font) !important;
  font-family: var(--font-base) !important;
  font-weight: 400 !important;
}

.okiro__memberonly_box_para span {
  margin-left: 0.2rem;
  font-weight: 900;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
  .okiro__memberonly_box {
    padding: 2rem;
  }

  .okiro__memberonly_text h1 {
    font-size: 25px;
  }

  .okiro__memberonly_text p {
    font-size: 15px !important;
  }

  .okiro__memberonly .okiro__custom-button button {
    font-size: 17px;
  }
}

@media screen and (max-width: 350px) {
  .okiro__memberonly_box {
    padding: 2rem 1rem;
  }

  .okiro__memberonly_text h1 {
    font-size: 19px;
  }

  .okiro__memberonly_text p {
    font-size: 12px !important;
  }

  .okiro__memberonly .okiro__custom-button button {
    font-size: 14px;
  }
}

.okiro__spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid var(--accent-color);
  background: var(--accent-color);
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.okiro__mdContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  padding: 2rem 6rem;
}

.okiro__mdContent p {
  font-size: 18px;
  font-family: var(--font-alt);
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-font);
  margin: 0.3rem 0;
}

.okiro__mdContent h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-base);
  margin-top: 0.9rem;
}

.okiro__mdContent p code {
  background: #e8eaff;
  padding: 0.2rem 0.5rem;
  text-align: center;
  font-style: italic;
  font-size: 13px;
  border-radius: 2px;
  font-weight: 600;
  margin: 0 0.1rem;
}

.okiro__mdContent pre {
  margin: 1rem 0;
  background: #f6f7ff;
  padding: 1rem;

  overflow-x: auto;
}

.okiro__mdContent strong {
  font-weight: 700;
}

.okiro__mdContent img {
  width: 100%;
}

.okiro__mdContent ul {
  margin: 0.7rem 0rem;
  font-family: var(--font-base);
  color: var(--color-font);
  line-height: 1.5;
  font-size: 18px;
}

@media screen and (max-width: 800px) {
  .okiro__mdContent {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 580px) {
  .okiro__mdContent {
    padding: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .okiro__mdContent {
    padding: 2rem 1.2rem;
  }
}

.okiro__homepage__latest {
  display: flex;
  flex-direction: row;
  margin: 3rem 0;
}

.okiro__homepage__latest-img {
  flex: 4 1;
  display: flex;
  max-height: 600px;
  margin: 0rem 1rem;
}

.okiro__homepage__latest-img img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  object-fit: cover;
  cursor: pointer;
}

.okiro__homepage__latest-img a {
  width: 100%;
}

.okiro__homepage__latest-content {
  flex: 3 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 1rem;
}

.okiro__homepage__latest-content_tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.all_tags {
  margin: 0.2rem;
}

.all_tags:first-child {
  margin-left: 0;
}

.all_tags:last-child {
  margin-right: 0;
}

.okiro__homepage__latest-content h2 {
  margin-top: 1rem;
  width: 100%;
  font-size: 54px;
  color: var(--color-font);
  line-height: 1.2;
  font-weight: 900;
  font-family: var(--font-base);
  cursor: pointer;
  margin-bottom: 3rem;
}

.okiro__homepage__latest-content_shortpara {
  display: flex;
  flex-direction: column;
  margin: 0rem 0rem 2rem;
}

.okiro__homepage__latest-content_shortpara svg {
  width: 90px;
  fill: rgba(0, 0, 0, 0.06);
}

.okiro__homepage__latest-content_shortpara p {
  margin-top: 0.5rem;
  font-family: var(--font-alt);
  font-size: 18px;
  font-weight: 800;
  width: 90%;
  color: var(--color-font);
  line-height: 1.5;
}

.okiro__author {
  display: flex;
  flex-direction: row;
}

.okiro__author-img {
  display: flex;
}

.okiro__author-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.okiro__author-info {
  margin-left: 0.7rem;
  display: flex;
  flex-direction: column;
}

.okiro__author-info h3 {
  margin-bottom: 0.1rem;
  font-family: var(--font-base);
  color: var(--color-font);
  font-size: 16px;
  cursor: pointer;
  margin-top: 0rem;
}

.okiro__author-info p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  font-family: var(--font-alt);
  color: var(--color-font);
}

@media screen and (max-width: 1000px) {
  .okiro__homepage__latest-content_shortpara {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .okiro__homepage__latest {
    flex-direction: column;
  }

  .okiro__homepage__latest-content_shortpara {
    display: flex;
  }

  .okiro__homepage__latest-img {
    margin-top: 1rem;
  }

  .okiro__homepage__latest-content {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 600px) {
  .okiro__homepage__latest-content h2 {
    font-size: 36px;
  }

  .okiro__homepage__latest-img {
    max-height: 450px;
  }
}

@media screen and (max-width: 450px) {
  .okiro__homepage__latest-img {
    max-height: 370px;
    margin: 0 0.5rem;
  }
}

.okiro__socialicon {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin: 0.3rem 0.65rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #ededf1;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  cursor: pointer;
}

.okiro__socialicon:hover {
  -webkit-transform: scale(0.94);
          transform: scale(0.94);
}

.okiro__nextPrePost {
  flex: 1 1;
  display: flex;
  flex-direction: row;

  padding: 1rem;
  background: #ede4ff;
  margin: 1rem;
  border-radius: 1rem;
  cursor: pointer;

  max-width: 610px;

  transition: -webkit-transform 0.2s ease;

  transition: transform 0.2s ease;

  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.okiro__nextPrePost:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.okiro__nextPrePost_img {
  display: flex;
  width: 50%;
  max-width: 150px;
  height: 150px;
  border-radius: 1rem;
}

.okiro__nextPrePost_img img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.okiro__nextPrePost_content {
  display: flex;
  flex-direction: column;

  padding: 1rem;
}

.okiro__nextPrePost_content p {
  margin: 0.5rem 0;
  font-size: 13px;
  font-weight: 900;
  color: var(--color-font);
  font-family: var(--font-alt);
  line-height: 1.5;
}

.okiro__nextPrePost_content h2 {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.3;
  color: var(--color-font);
  font-family: var(--font-base);
}

.alignStyles {
  text-align: -webkit-right;
  text-align: -moz-right;
}

.flexDirectionStyles {
  flex-direction: row-reverse;
}

@media screen and (max-width: 500px) {
  .okiro__nextPrePost_content h2 {
    font-size: 18px;
  }

  .okiro__nextPrePost {
    flex-direction: column !important;
  }

  .okiro__nextPrePost_img {
    max-width: unset;
    width: 100%;
  }
}

.okiro__post-card {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  min-width: 210px;
  max-width: 290px;

  padding: 1.3rem;
  background: #f4f4f7;
  border-radius: 20px;

  margin: 1rem;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.okiro__post-card:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.okiro__post-card__image {
  display: flex;
  height: 175px;
}

.okiro__post-card__image a {
  width: 100%;
}

.okiro__post-card__image img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

.okiro__post-card__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;

  margin: 0.8rem 0.5rem 0rem;
}

.okiro__post-card__content h3 {
  font-size: 19px;
  font-weight: 900;
  color: var(--color-font);
  line-height: 1.5;
  font-family: var(--font-base);
  cursor: pointer;
}

.okiro__post-card__content h3::first-letter {
  text-transform: capitalize;
}

.okiro__post-card__content p {
  font-size: 13px;
  font-family: var(--font-alt);
  font-weight: 800;
  color: var(--color-font);
  word-break: break-all;
  cursor: pointer;

  margin-top: 0.4rem;
}

@media screen and (max-width: 300px) {
  .okiro__post-card {
    min-width: 100%;
  }
}

.okiro__homepage__previewPosts {
  width: 100%;
  display: flex;
  flex-direction: row;

  margin: 1rem 0;
}

.okiro__hompage__previewPosts-title {
  width: 25px;
  display: flex;
  position: relative;
  margin-right: 1rem;
}

.okiro__hompage__previewPosts-title h2 {
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  -webkit-transform: translate(-45%, 70px) rotate(-90deg);
          transform: translate(-45%, 70px) rotate(-90deg);
  text-align: right;
  letter-spacing: 8px;
  text-transform: uppercase;
  font-family: var(--font-base);
  width: -webkit-max-content;
  width: max-content;
}

.okiro__hompage__previewPosts-zigzag {
  display: none;
}

.okiro__homepage__previewPosts-posts {
  flex: 5 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media screen and (max-width: 1000px) {
  .okiro__homepage__previewPosts-posts {
    justify-content: center;
  }
}

@media screen and (max-width: 900px) {
  .okiro__homepage__previewPosts {
    flex-direction: column;
  }

  .okiro__hompage__previewPosts-title {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    margin-bottom: 1rem;
  }

  .okiro__hompage__previewPosts-title h2 {
    -webkit-transform: none;
            transform: none;
    text-align: center;
    position: unset;

    font-size: 15px;
  }

  .okiro__hompage__previewPosts-zigzag {
    display: flex;
    width: 90px;
    fill: rgba(0, 0, 0, 0.06);
    margin-top: 10px;
  }
}

@media screen and (max-width: 450px) {
  .okiro__homepage__previewPosts {
    padding: 0rem 1rem;
  }
}

.okiro__blogpage {
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
}

.okiro__blogpage .okiro__homepage__latest {
  margin-top: 1rem;
}

.okiro__blogpage__social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.okiro__blogpage__nextpre {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 2rem;
}

.okiro__blogpage__nextpre a {
  width: 100%;
}

.okiro__blogpage-seemorePosts {
  display: flex;
  flex-direction: row;

  padding: 1rem 2rem;
}

.okiro__blogpage-error {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.okiro__blogpage-error div {
  display: flex;

  background: #fff5ea;
  border-radius: 1rem;
}

.okiro__blogpage-error div h2 {
  margin: 0;
  padding: 1rem;
  line-height: 1.5;
  font-weight: 900;
  color: var(--color-font);
}

@media screen and (max-width: 1120px) {
  .okiro__blogpage__nextpre {
    flex-direction: column;
  }
}

@media screen and (max-width: 800px) {
  .okiro__blogpage {
    padding: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .okiro__blogpage {
    padding: 2rem 0.5rem;
  }
}

@media screen and (max-width: 450px) {
  .okiro__blogpage-seemorePosts {
    padding: 1rem 0.5rem;
  }
}

.okiro__authorpage {
  display: flex;
  flex-direction: column;

  margin: 1rem 0;
  padding: 2rem;
  -webkit-user-select: none;
          user-select: none;
}

@media screen and (max-width: 450px) {
  .okiro__authorpage {
    padding: 2rem 0.5rem;
  }
}

.okiro__seealso {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 2rem;
  text-align: center;
}

.okiro__seealso h3 {
  font-size: 22px;
  font-weight: 900;
  font-family: var(--font-base);
  line-height: 1.5;
  color: var(--color-font);
}

.okiro__seealso-div {
  margin: 1rem 0rem;
  display: flex;
  flex-direction: row;

  background: #f4f4f7;
  border-radius: 1.2rem;
  padding: 1rem;

  width: 350px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.okiro__seealso-div:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.okiro__seealso-div__img,
.okiro__seealso-div__name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.okiro__seealso-div__img img {
  width: 90px;
  height: 90px;
  border-radius: 0.5rem;
  cursor: pointer;
}

.okiro__seealso-div__name p {
  line-height: 1.1;
  font-size: 19px;
  font-weight: 900;
  font-family: var(--font-base);
  color: var(--color-font);
  cursor: pointer;
  margin-left: 1rem;
}

.okiro__seealso-div__name p::first-letter {
  text-transform: capitalize;
}

@media screen and (max-width: 450px) {
  .okiro__seealso-div {
    width: auto;
  }

  .okiro__seealso-div__name {
    text-align: left;
  }
}

@media screen and (max-width: 270px) {
  .okiro__seealso-div {
    flex-direction: column;
  }

  .okiro__seealso-div p {
    margin: 1rem 0rem 0rem;
  }

  .okiro__seealso-div__name {
    text-align: center;
  }
}

.okiro__article-card {
  /* min-width: 290px; */
  display: flex;
  flex-direction: column;
  max-width: 350px;

  background: #ffeab9;
  border-radius: 25px;

  margin: 1rem 1.5rem;

  transition: -webkit-transform 0.2s ease;

  transition: transform 0.2s ease;

  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.okiro__article-card:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.okiro__article-card_img {
  display: flex;
  width: 100%;
}

.okiro__article-card_img a {
  width: 100%;
}

.okiro__article-card_img img {
  width: 100%;
  height: 330px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  cursor: pointer;
}

.okiro__article-card_content {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.okiro__article-card_content-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.okiro__article-card_content-name h3 {
  font-size: 26px;
  line-height: 1.2;
  width: 100%;
  color: var(--color-font);
  font-weight: 900;
  font-family: var(--font-base);
  cursor: pointer;
}

.okiro__article-card_content-name p {
  margin-top: 0.5rem;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 800;
  color: var(--color-font);
  width: 100%;
  font-family: var(--font-alt);
  cursor: pointer;
}

.okiro__article-card_content-tag {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.okiro__article-card_content-tag_private {
  width: 35px;
  height: 35px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.okiro__article-card_content-tag_private svg {
  fill: var(--color-font);
}

.okiro__authorDetails {
  display: flex;
  flex-direction: column;

  padding: 2rem 4rem;
}

.okiro__authorDetails .okiro__infocard {
  max-height: unset;
}

.okiro__authorDetails_articles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin: 1rem 0;
}

.okiro__authorDetails_authorBox {
  display: flex;
  justify-content: space-between;
}

.okiro__authorDetails_authorContent {
  display: flex;
  flex: 4 1;
  height: 100%;

  background: #fff;
  padding: 1.5rem;

  border-radius: 1rem;
}

.okiro__authorDetails_authorInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding: 1rem;
}

.okiro__authorDetails_authorImage {
  display: flex;
  max-width: 210px;

  margin-right: 1.3rem;
  border-radius: 1rem;
}

.okiro__authorDetails_authorImage img {
  width: 100%;
  border-radius: 1rem;
}

.okiro__authorDetails_authorContent h1 {
  font-size: 45px;
  text-align: left;
  line-height: 1.2;
  font-weight: 800;
  font-family: var(--font-base);
  color: var(--color-font);
}

.okiro__authorDetails_authorContent svg {
  width: 90px;
  fill: rgba(0, 0, 0, 0.06);

  margin-top: 1.5rem;
}

.okiro__authorDetails_authorContent p {
  margin: 1rem 0rem;
  font-weight: 900;
  font-family: var(--font-alt);
  font-size: 18px;
  line-height: 1.5;
  color: var(--color-font);
}

.okiro__authorDetails_stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1;

  margin-left: 2rem;
}

.okiro__authorDetails_authorPosts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: #fff;
  border-radius: 1rem;
  max-height: 150px;
  width: 100%;
  flex: 2 1;
}

.okiro__authorDetails_authorPosts h2 {
  font-size: 54px;
  line-height: 1;
  font-weight: 800;
  text-align: center;
  color: var(--color-font);
  font-family: var(--font-base);
  margin: 0;
}

.okiro__authorDetails_authorPosts span {
  margin-top: 0.2rem;
  font-weight: 900;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  font-family: var(--font-alt);
  color: var(--color-font);
}

.okiro__authorDetails_authorLinks {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 0.2 1;

  margin-left: 1rem;
}

.okiro__authorDetails_authorLinks .okiro__socialicon {
  background: #fff;
}

.okiro__authorDetails_authorLinks .okiro__socialicon:first-child {
  margin-top: 0;
}

@media screen and (max-width: 1100px) {
  .okiro__authorDetails_authorBox {
    flex-direction: column;
  }

  .okiro__authorDetails_authorPosts {
    margin: 1rem 1rem 1rem 0;
    max-height: unset;

    padding: 1rem;
  }

  .okiro__authorDetails_stats {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 0;
  }

  .okiro__authorDetails_authorPosts {
    max-width: 150px;
  }

  .okiro__authorDetails_authorLinks {
    flex-direction: row;
  }

  .okiro__authorDetails_authorLinks .okiro__socialicon:first-child {
    margin-left: 0.3rem;
  }
}

@media screen and (max-width: 915px) {
  .okiro__authorDetails_authorContent {
    flex-direction: column;
  }

  .okiro__authorDetails_authorImage {
    width: 100%;
    height: 250px;
    max-width: unset;
    margin-right: 0;
  }

  .okiro__authorDetails_authorImage img {
    width: 100%;
  }

  .okiro__authorDetails_authorContent h1 {
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 575px) {
  .okiro__authorDetails {
    padding: 2rem 1.2em;
  }

  .okiro__authorDetails_authorContent h1 {
    font-size: 32px;
  }

  .okiro__authorDetails_authorContent p {
    font-size: 15px;
  }

  .okiro__authorDetails_stats {
    flex-direction: column;
  }

  .okiro__authorDetails_authorLinks {
    margin-left: 0;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .okiro__authorDetails_authorPosts {
    margin: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .okiro__authorDetails_authorContent h1 {
    font-size: 24px;
  }

  .okiro__authorDetails_authorInfo {
    padding: 0rem;
  }
}

@media screen and (max-width: 360px) {
  .okiro__authorDetails {
    padding: 2rem 0.5rem;
  }
}

.okiro__tagpage {
  display: flex;
  flex-direction: column;

  margin: 1rem 0;
  padding: 2rem;
  -webkit-user-select: none;
          user-select: none;
}

.okiro__tagpage .okiro__post-card__content {
  margin: 0rem 0.5rem;
}

@media screen and (max-width: 450px) {
  .okiro__tagpage {
    padding: 2rem 0.5rem;
  }
}

.catalog__homepage__menu {
    width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: end;
  
    margin: 1rem 0;
  }

  .catalog__menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
 
  }
  
  .catalog__menu__logo {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .catalog__menu__logo img {
    width: 200px;
    height: 60px;
  }
  
  .catalog__menu__links,
  .catalog__menu__sign {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .catalog__menu__sign {
    justify-content: flex-end;
  }
  
  .style-font{
    font-style: italic;
  }
  
  .catalog__menu__links ul,
  .catalog__menu__sign ul {
    display:block;
    justify-content:end;
    align-items: center;
  }
  
  .catalog__menu__links li,
  .catalog__menu__sign li,
  .catalog__menu__hamburger--linksDiv li {
    display: flex;
    list-style: none;
    margin: 1.5rem 3.7rem;
  
    font-family: var(--font-base);
    font-size: 19px;
    line-height: 1.5;
    color: var(--color-font);
    font-weight: 900;
    cursor: pointer;
    position: relative;
    font-weight: 100;
  }
  
  .catalog__menu__links {
    position: relative;
  }
  
  .catalog__menu__dotLinks {
    display: flex;
    justify-content: flex-start;
    min-width: 170px;
    position: absolute;
  
    margin: 2rem 0rem 0rem -3rem;
  
    background: var(--color-two);
    padding: 1rem 0.7rem;
    border-radius: 10px;
  
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  
  .catalog__menu__dotLinks ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .catalog__menu__dotLinks li {
    text-align: left;
    margin: 0.3rem 0.7rem;
  }
  
  .special-link:hover:before,
  .special-link:hover:before {
    position: absolute;
    width: 7px;
    height: 7px;
    content: "";
    border-radius: 100%;
    background: var(--color-three);
    top: 1px;
    right: -7px;
  
    transition: background-color 0.3s ease;
  }
  
  .special-link:active:before,
  .special-link:focus:before {
    background: var(--accent-color);
  }
  
  .isActive::before {
    background: var(--accent-color);
    position: absolute;
    width: 7px;
    height: 7px;
    content: "";
    border-radius: 100%;
    top: 1px;
    right: -7px;
  }
  
  .catalog__menu__hamburger {
    display: none;
    flex: 1 1;
    justify-content: flex-end;
    align-items: center;
  
    position: relative;
  }
  
  .catalog__menu__hamburger--linksDiv {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 1.5rem;
  
    background-color: var(--color-two);
    top: 170%;
    right: -1%;
    border-radius: 10px;
    min-width: 170px;
    z-index: 5;
  
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  
  .catalog__menu__hamburger--linksDiv ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0rem 1rem;
  }
  
  .catalog__menu__hamburger--linksDiv li {
    margin: 0.3rem;
    font-size: 16px;
    -webkit-user-select: none;
            user-select: none;
  }
  
  .catalog__menu__hamburger--linkDiv-search {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: var(--accent-color);
    padding: 1rem;
    margin-top: 0.7rem;
  
    font-family: var(--font-base);
    letter-spacing: 0.5px;
    color: #fff;
    font-weight: 700;
    text-transform: capitalize;
    cursor: pointer;
  
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .catalog__menu__hamburger--linkDiv-search svg {
    margin-left: 10px;
  }


  @media screen and (max-width: 1485px) {

    .catalog__homepage__menu {
        width: 20%;
      }

  }
  
  @media screen and (max-width: 1010px) {
    .catalog__menu__sign,
    .catalog__menu__links {
      display: none;
    }
  
    .catalog__menu__logo {
      justify-content: flex-start;
    }
  
    .catalog__menu__hamburger {
      display: flex;
    }


  }
  
  @media screen and (max-width: 600px) {
    .catalog__menu {
      padding: 1rem;
    }
  
    .catalog__menu__logo img {
      width: 180px;
      height: 50px;
    }

    .catalog__homepage__menu{
        display: none;
    }
  }
  
.okiro__tagpage {
  display: flex;
  flex-direction: inherit;

  margin: 1rem 0;
  padding: 2rem;
  -webkit-user-select: none;
          user-select: none;
}

.okiro__tagpage .okiro__post-card__content {
  margin: 0rem 0.5rem;
}

@media screen and (max-width: 450px) {
  .okiro__tagpage {
    padding: 2rem 0.5rem;
  }
}

.okiro__product-card {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 330px;

  padding: 1.3rem;
  /* background: #f4f4f7; */
  border-radius: 2px;

  margin: 1rem;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.okiro__product-card:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.okiro__product-card__image {
  display: flex;
  height: 260px;
}

.okiro__product-card__image a {
  width: 100%;
}

.okiro__product-card__image img {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  cursor: pointer;
}

.okiro__product-card__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;

  margin: 0.8rem 0.5rem 0rem;
}

.okiro__product-card__content h3 {
  font-size: 16px;
  font-weight: 900;
  color: var(--color-font);
  line-height: 1.5;
  font-family: var(--font-base);
  cursor: pointer;
}

.okiro__product-card__content h3::first-letter {
  text-transform: capitalize;
}

.okiro__product-card__content p {
  font-size: 13px;
  font-family: var(--font-alt);
  font-weight: 800;
  word-break: break-all;
  cursor: pointer;

  text-transform: uppercase;
  color:#a9b1be !important;

  margin-top: 0.4rem;
}

.product-bottom-details {
  overflow: hidden;
  padding-top: 20px;


  margin: 0.8rem 0.5rem 0rem;
}

.product-bottom-details div {
  float: left;
  width: 50%;
}

.start-sty{
  font-size: 15px;
  color: #fbb72c;
  font-weight: 600;
}

.product-price {
  font-size: 25px;
  /* color: #89bbfe; */
  color: #589efb;
  font-weight: 600;
}

.product-price small {
  font-size: 80%;
  font-weight: 400;
  text-decoration: line-through;
  display: inline-block;
  margin-right: 5px;
}

.product-links {
  text-align: right;
}

.product-links a {
  display: inline-block;
  margin-left: 1px;
  color: #f0b43b;
  transition: 0.3s;
  font-size: 20px;
}

.product-links a:hover {
  color: #fbb72c;
}

@media screen and (max-width: 300px) {
  .okiro__product-card {
    min-width: 100%;
  }
}

.okiro__homepage__previewPosts {
  width: 80%;
  display: flex;
  flex-direction: row;

  margin: 1rem 0;
}

.okiro__hompage__previewPosts-title {
  width: 25px;
  display: flex;
  position: relative;
  margin-right: 1rem;
}

.okiro__hompage__previewPosts-title h2 {
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  -webkit-transform: translate(-45%, 70px) rotate(-90deg);
          transform: translate(-45%, 70px) rotate(-90deg);
  text-align: right;
  letter-spacing: 8px;
  text-transform: uppercase;
  font-family: var(--font-base);
  width: -webkit-max-content;
  width: max-content;
}

.okiro__hompage__previewPosts-zigzag {
  display: none;
}

.okiro__homepage__previewPosts-posts {
  flex: 4 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media screen and (max-width: 1000px) {
  .okiro__homepage__previewPosts-posts {
    justify-content: center;
  }
}

@media screen and (max-width: 900px) {
  .okiro__homepage__previewPosts {
    flex-direction: column;
  }

  .okiro__hompage__previewPosts-title {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    margin-bottom: 1rem;
  }

  .okiro__hompage__previewPosts-title h2 {
    -webkit-transform: none;
            transform: none;
    text-align: center;
    position: unset;

    font-size: 15px;
  }

  .okiro__hompage__previewPosts-zigzag {
    display: flex;
    width: 90px;
    fill: rgba(0, 0, 0, 0.06);
    margin-top: 10px;
  }
}

@media screen and (max-width: 450px) {
  .okiro__homepage__previewPosts {
    padding: 0rem 1rem;
    width: 100%;
  }

}

@media screen and (max-width: 600px) {
  .okiro__homepage__previewPosts {
    width: 100%;
  }


}

.okiro__tagDetails {
  display: flex;
  flex-direction: column;

  padding: 2rem 4rem;
}

.okiro__tagDetails_articles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin: 1rem 0;
}

.okiro__tagDetails_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.okiro__tagDetails_box_type {
  min-width: 150px;
  min-height: 150px;
  border-radius: 1rem;
  padding: 1rem 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #fff;
}

.okiro__tagDetails_box_type h1 {
  font-size: 45px;
  line-height: 1.2;
  font-weight: 800;
  font-family: var(--font-base);
  color: var(--color-font);
  text-transform: capitalize;
}

.okiro__tagDetails_box_total {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-left: 1.5rem;
  background: #fff;
  min-width: 150px;
  min-height: 150px;
  text-align: center;

  padding: 1rem;

  border-radius: 1rem;
}

.okiro__tagDetails_box_total h1 {
  font-size: 54px;
  line-height: 1;
  font-weight: 900;
  font-family: var(--font-base);
  color: var(--color-font);
}

.okiro__tagDetails_box_total span {
  margin-top: 5px;
  font-weight: 900;
  font-family: var(--font-alt);
  font-size: 18px;
  line-height: 1;
  color: var(--color-font);
}

@media screen and (max-width: 700px) {
  .okiro__tagDetails_box {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
  }

  .okiro__tagDetails_box_total {
    margin: 1.5rem 0rem 0rem;
  }
}

@media screen and (max-width: 575px) {
  .okiro__tagDetails {
    padding: 2rem 1.2em;
  }
}

@media screen and (max-width: 360px) {
  .okiro__tagDetails {
    padding: 2rem 0.5rem;
  }
}

@media screen and (max-width: 300px) {
  .okiro__tagDetails_box_type h1 {
    font-size: 24px;
  }

  .okiro__tagDetails_box_type {
    padding: 1rem;
  }
}

.okiro__notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.okiro__notFound h2 {
  font-size: 54px;
  line-height: 1.5;
  font-family: var(--font-base);
  color: var(--color-font);

  padding: 0 2rem;
}

.okiro__notFound p {
  margin-top: 1rem;
  font-size: 24px;
  font-family: var(--font-alt);
  color: var(--color-font);
  text-align: center;

  padding: 0 2rem;
}

.okiro__features {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 2rem;
}

.okiro__features h1 {
  font-size: 62px;
  color: var(--color-font);
  line-height: 1.2;
  font-weight: 800;
  font-family: var(--font-base);
}

.okiro__features_content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  margin: 2rem 12vw;
}

.okiro__features_content h3 {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 900;
  font-family: var(--font-alt);
  color: var(--color-font);
}

.okiro__features_content svg {
  width: 90px;
  fill: rgba(0, 0, 0, 0.06);

  margin-top: 5rem;
}

.okiro__features_content h2 {
  margin: 2rem 0 1rem;
  color: var(--color-font);
  font-size: 31px;
  line-height: 1.4;
  font-weight: 900;
  font-family: var(--font-alt);
}

.okiro__features_content ul {
  margin: 0 2rem;
}

.okiro__features_content ul li {
  margin-bottom: 10px;
  font-family: var(--font-alt);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-font);
}

.okiro__features_content-credits {
  display: flex;
  flex-direction: column;
}

.okiro__features_content-credits p {
  line-height: 1.65;
  font-family: var(--font-alt);
  font-size: 20px;
  font-weight: 400;
  color: var(--color-font);
}

.okiro__features_content-credits span {
  font-weight: 800;
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  .okiro__features_content {
    margin: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .okiro__features {
    padding: 2rem 0.5rem;
  }

  .okiro__features_content {
    margin: 2rem 0.5rem;
  }

  .okiro__features h1 {
    font-size: 46px;
  }

  .okiro__features_content h3 {
    font-size: 18px;
  }

  .okiro__features_content h2 {
    font-size: 25px;
  }

  .okiro__features_content ul li,
  .okiro__features_content-credits p {
    font-size: 15px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.main-p{
  background-color:#03b0cb0c;
}

a {
  text-decoration: none;
  color: unset;
}

/* Hamburger Animation */
.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 35px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* Slide up animation */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.okiro__aboutus {
  display: flex;
  flex-direction: column;
}

.okiro__aboutus-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 2rem 6rem;
}

.okiro__aboutus-content h1 {
  font-size: 64px;
  line-height: 1.5;
  font-family: var(--font-base);
  color: var(--color-font);
  font-weight: 800;
}

.okiro__aboutus-content p {
  padding: 1rem 3rem;
  text-align: center;

  display: flex;
  font-size: 20px;
  color: var(--color-font);
  line-height: 1.65;
  font-weight: 400;
}

.okiro__aboutus-creators {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: relative;
  background: url(/static/media/ddwhite.5948020b.svg);
  background-position: center;
  background-size: cover;

  margin-top: 2rem;

  padding: 6rem;
}

.okiro__aboutus-creators::before {
  background: linear-gradient(-5deg, #fff 16px, transparent 0),
    linear-gradient(15deg, #fff 16px, transparent 0);
  background-position: top;
  background-repeat: repeat-x;
  background-size: 32px 32px;
  content: " ";
  display: block;
  position: absolute;
  top: 0%;
  left: 0px;
  width: 100%;
  height: 32px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.okiro__aboutus-creators::after {
  background: linear-gradient(-5deg, #fff 16px, transparent 0),
    linear-gradient(15deg, #fff 16px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 32px 32px;
  content: " ";
  display: block;
  position: absolute;
  bottom: 0%;
  left: 0px;
  width: 100%;
  height: 32px;
}

.okiro__aboutus-creators-div {
  display: flex;
  margin: 0 10vw;

  background: #fff;
}

.okiro__aboutus-creators-div_img {
  width: 450px;
  display: flex;

  position: relative;
  z-index: 1;
}

.okiro__aboutus-creators-div_img img {
  width: 100%;
  min-height: 450px;
}

.okiro__aboutus-creators-div_info {
  width: 450px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  padding: 2rem;
}

.okiro__aboutus-creators-div_infohead {
  margin-bottom: 1rem;
}

.okiro__aboutus-creators-div_infohead h3 {
  font-size: 34px;
  color: var(--color-font);
  font-family: var(--font-base);
  line-height: 1.2;
  font-weight: 800;
  text-transform: capitalize;
}

.okiro__aboutus-creators-div_infohead p {
  font-size: 15px;
  margin-top: 0.3rem;
  color: #9b9b9b;
  font-family: var(--font-alt);
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.7px;
}

.okiro__aboutus-creators-div_infopara p {
  margin: 1rem 0;
  font-size: 17px;
  color: var(--color-font);
  line-height: 1.56;
  font-family: var(--font-alt);
}

@media screen and (max-width: 950px) {
  .okiro__aboutus-creators-div {
    flex-direction: column;
    margin: 0.5rem 0;
  }

  .okiro__aboutus-content {
    padding: 2rem;
  }

  .okiro__aboutus-content p {
    padding: 1rem 0rem;
  }
}

@media screen and (max-width: 750px) {
  .okiro__aboutus-creators {
    padding: 6rem 1rem;
  }
}

@media screen and (max-width: 550px) {
  .okiro__aboutus-content h1 {
    font-size: 34px;
  }

  .okiro__aboutus-creators-div {
    width: 100%;
  }

  .okiro__aboutus-creators-div_img,
  .okiro__aboutus-creators-div_info {
    width: 100%;
  }

  .okiro__aboutus-creators-div_img img {
    min-height: unset;
    height: 100%;
  }

  .okiro__aboutus-creators-div_info {
    padding: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .okiro__aboutus-content {
    padding: 2rem 1rem;
  }

  .okiro__aboutus-content p,
  .okiro__aboutus-creators-div_info p {
    font-size: 15px;
  }
}

.okiro__homepage__latest {
  display: flex;
  flex-direction: row;
  margin: 3rem 0;
  width: 70%;
}

.okiro__homepage__latest-img {
  flex: 4 1;
  display: flex;
  max-height: 600px;
  margin: 0rem 0rem;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.okiro__homepage__latest-img img {
  width: 80%;
  height: 100%;

  max-height: 30rem;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
}

.okiro__homepage__latest-img a {
  width: 100%;
}

.okiro__homepage__latest-content {
  flex: 3 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 30rem;
  border-radius: 1px;
  background-color: lavenderblush;

  padding: 1rem;
}

.okiro__homepage__latest-content_tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.okiro__homepage__latest-content_short {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.okiro__button_products__fields {
  padding-top: 1.2rem;
  justify-content: center;
}

.okiro__button_products__fields .btn-whats{
  margin-left: 4px;
  background: #cacaca;
  color: #0a0a0a;

}

.okiro__button_products__fields button{
  padding: 0.9rem 0.5rem;
}

.latest-product-footer{
  border-top:#0a0a0a;
  justify-content: center;
  display: flex;
  margin: auto;
}

.all_tags {
  margin: 0.2rem;
}

.all_tags:first-child {
  margin-left: 0;
}

.all_tags:last-child {
  margin-right: 0;
}

.okiro__homepage__latest-content h2 {
  margin-top: 1rem;
  width: 100%;
  font-size: 20px;
  color: var(--color-font);
  line-height: 1.2;
  font-weight: 900;
  font-family: var(--font-base);
  cursor: pointer;
  margin-bottom: 3rem;
}

.okiro__homepage__latest-content_shortpara {
  display: flex;
  flex-direction: column;
  margin: 0rem 0rem 2rem;
}

.okiro__homepage__latest-content_shortpara svg {
  width: 90px;
  fill: rgba(0, 0, 0, 0.06);
}

.okiro__homepage__latest-content_shortpara p {
  margin-top: 0.5rem;
  font-family: var(--font-alt);
  font-size: 18px;
  font-weight: 800;
  width: 90%;
  color: var(--color-font);
  line-height: 1.5;
}

.okiro__author {
  display: flex;
  flex-direction: row;
}

.okiro__author-img {
  display: flex;
}

.okiro__author-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.okiro__author-info {
  margin-left: 0.7rem;
  display: flex;
  flex-direction: column;
}

.okiro__author-info h3 {
  margin-bottom: 0.1rem;
  font-family: var(--font-base);
  color: var(--color-font);
  font-size: 16px;
  cursor: pointer;
  margin-top: 0rem;
}

.okiro__author-info p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  font-family: var(--font-alt);
  color: var(--color-font);
}



.input-number-group {
  display: flex;
  justify-content: center;
}

.input-number-group input[type=number]::-webkit-inner-spin-button,
.input-number-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
          appearance: none;
}

.input-number-group .input-group-button {
  line-height: calc(80px/2 - 5px);
}

.input-number-group .input-number {
  width: 80px;
  padding: 0 12px;
  vertical-align: top;
  text-align: center;
  outline: none;
  display: block;
  margin: 0;
}

.input-number-group .input-number,
.input-number-group .input-number-decrement,
.input-number-group .input-number-increment {
  border: 1px solid #cacaca;
  height: 40px;
  -webkit-user-select: none;
          user-select: none;
  border-radius: 0;
}

.input-number-group .input-number-decrement,
.input-number-group .input-number-increment {
  display: inline-block;
  width: 40px;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 400;
}

.input-number-group .input-number-decrement {
  margin-right: 0.3rem;
}

.input-number-group .input-number-increment {
  margin-left: 0.3rem;
}



@media screen and (max-width: 1000px) {
  .okiro__homepage__latest-content_shortpara {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .okiro__homepage__latest {
    flex-direction: column;
  }

  .okiro__homepage__latest-content_shortpara {
    display: flex;
  }

  .okiro__homepage__latest-img {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .okiro__homepage__latest-content {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 600px) {
  .okiro__homepage__latest-content h2 {
    font-size: 20px;
  }

  .okiro__homepage__latest-img {
    max-height: 450px;
  }

  .okiro__homepage__latest {
    width: 100%;
  }

  .okiro__homepage__latest-img img {
    width: 100%;
    height: 100%;
  }

}

@media screen and (max-width: 450px) {
  .okiro__homepage__latest-img {
    max-height: 370px;
    /* margin: 0 0.5rem; */
  }
}

.okiro__productpage {
  /* padding: 2rem 4rem; */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.okiro__productpage .okiro__homepage__latest {
  margin-top: 1rem;
}

.okiro__productpage__social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.okiro__productpage__nextpre {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 2rem;
}

.okiro__productpage__nextpre a {
  width: 100%;
}

.okiro__productpage-seemorePosts {
  display: flex;
  flex-direction: row;

  padding: 1rem 2rem;
}

.okiro__productpage-error {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.okiro__productpage-error div {
  display: flex;

  background: #fff5ea;
  border-radius: 1rem;
}

.okiro__productpage-error div h2 {
  margin: 0;
  padding: 1rem;
  line-height: 1.5;
  font-weight: 900;
  color: var(--color-font);
}

@media screen and (max-width: 1120px) {
  .okiro__productpage__nextpre {
    flex-direction: column;
  }
}

@media screen and (max-width: 800px) {
  .okiro__productpage {
    padding: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .okiro__productpage {
    padding: 2rem 0.5rem;
  }
}

@media screen and (max-width: 450px) {
  .okiro__productpage-seemorePosts {
    padding: 1rem 0.5rem;
  }
}


.okiro__homepage__latest {
  display: flex;
  flex-direction: row;
  margin: 3rem 0;
  width: 70%;
}

.okiro__homepage__latest-img {
  flex: 4;
  display: flex;
  max-height: 600px;
  margin: 0rem 0rem;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.okiro__homepage__latest-img img {
  width: 80%;
  height: 100%;

  max-height: 30rem;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
}

.okiro__homepage__latest-img a {
  width: 100%;
}

.okiro__homepage__latest-content {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 30rem;
  border-radius: 1px;
  background-color: lavenderblush;

  padding: 1rem;
}

.okiro__homepage__latest-content_tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.okiro__homepage__latest-content_short {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.okiro__button_products__fields {
  padding-top: 1.2rem;
  justify-content: center;
}

.okiro__button_products__fields .btn-whats{
  margin-left: 4px;
  background: #cacaca;
  color: #0a0a0a;

}

.okiro__button_products__fields button{
  padding: 0.9rem 0.5rem;
}

.latest-product-footer{
  border-top:#0a0a0a;
  justify-content: center;
  display: flex;
  margin: auto;
}

.all_tags {
  margin: 0.2rem;
}

.all_tags:first-child {
  margin-left: 0;
}

.all_tags:last-child {
  margin-right: 0;
}

.okiro__homepage__latest-content h2 {
  margin-top: 1rem;
  width: 100%;
  font-size: 20px;
  color: var(--color-font);
  line-height: 1.2;
  font-weight: 900;
  font-family: var(--font-base);
  cursor: pointer;
  margin-bottom: 3rem;
}

.okiro__homepage__latest-content_shortpara {
  display: flex;
  flex-direction: column;
  margin: 0rem 0rem 2rem;
}

.okiro__homepage__latest-content_shortpara svg {
  width: 90px;
  fill: rgba(0, 0, 0, 0.06);
}

.okiro__homepage__latest-content_shortpara p {
  margin-top: 0.5rem;
  font-family: var(--font-alt);
  font-size: 18px;
  font-weight: 800;
  width: 90%;
  color: var(--color-font);
  line-height: 1.5;
}

.okiro__author {
  display: flex;
  flex-direction: row;
}

.okiro__author-img {
  display: flex;
}

.okiro__author-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.okiro__author-info {
  margin-left: 0.7rem;
  display: flex;
  flex-direction: column;
}

.okiro__author-info h3 {
  margin-bottom: 0.1rem;
  font-family: var(--font-base);
  color: var(--color-font);
  font-size: 16px;
  cursor: pointer;
  margin-top: 0rem;
}

.okiro__author-info p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  font-family: var(--font-alt);
  color: var(--color-font);
}



.input-number-group {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.input-number-group input[type=number]::-webkit-inner-spin-button,
.input-number-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
          appearance: none;
}

.input-number-group .input-group-button {
  line-height: calc(80px/2 - 5px);
}

.input-number-group .input-number {
  width: 80px;
  padding: 0 12px;
  vertical-align: top;
  text-align: center;
  outline: none;
  display: block;
  margin: 0;
}

.input-number-group .input-number,
.input-number-group .input-number-decrement,
.input-number-group .input-number-increment {
  border: 1px solid #cacaca;
  height: 40px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 0;
}

.input-number-group .input-number-decrement,
.input-number-group .input-number-increment {
  display: inline-block;
  width: 40px;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 400;
}

.input-number-group .input-number-decrement {
  margin-right: 0.3rem;
}

.input-number-group .input-number-increment {
  margin-left: 0.3rem;
}



@media screen and (max-width: 1000px) {
  .okiro__homepage__latest-content_shortpara {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .okiro__homepage__latest {
    flex-direction: column;
  }

  .okiro__homepage__latest-content_shortpara {
    display: flex;
  }

  .okiro__homepage__latest-img {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .okiro__homepage__latest-content {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 600px) {
  .okiro__homepage__latest-content h2 {
    font-size: 20px;
  }

  .okiro__homepage__latest-img {
    max-height: 450px;
  }

  .okiro__homepage__latest {
    width: 100%;
  }

  .okiro__homepage__latest-img img {
    width: 100%;
    height: 100%;
  }

}

@media screen and (max-width: 450px) {
  .okiro__homepage__latest-img {
    max-height: 370px;
    /* margin: 0 0.5rem; */
  }
}
